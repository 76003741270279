import { render, staticRenderFns } from "./NoticeDetail.vue?vue&type=template&id=237e9762&scoped=true&"
import script from "./NoticeDetail.vue?vue&type=script&lang=js&"
export * from "./NoticeDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "237e9762",
  null
  
)

export default component.exports